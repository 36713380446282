import {dateFormat} from '@/utils/utils';

export default {
    data() {
        return {
            pagination: {
                pageNo:1,
                pageSize: 10,
                totalSize: 0,
                totalPages: 1
            },
            isLoading: false,
            dataList: []
        }
    },
    methods: {
        dateFormat(date, fmt = 'yyyy-mm-dd HH:MM:SS') {
            if (!date) {
                return date;
            }

            date = !isNaN(date) ? new Date(date) : date;
            return dateFormat(fmt, date);
        },
        paramFormat(query) {
            const {
                pageNo,
                pageSize
            } = this.pagination;

            query = JSON.parse(JSON.stringify(query));
            return Object.assign(query, {
                pageNo,
                pageSize
            });
        },
        handleSizeChange(e) {
            this.pagination.pageSize = e;
            this.search();
        },
        handleCurrentChange(e) {
            this.pagination.pageNo = e;
            this.search();
        },
    },
    created() {
    },
    mounted() {
        this.search()

    }
}
