<template>
  <div class="page">
    <!--信息统计框-->
    <!-- style="height: 50px;padding: 20px 32px;" -->
    <div class="search-box page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ total }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div class="row_new padding-bottom19">
        <el-button type="primary" size="small" @click="doAdd">新增</el-button>
      </div>
      <el-table v-loading="isLoading" :data="dataList"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" border class="common-table" row-key="id"
        size="medium" style="width: 100%">
        <el-table-column align="left" label="权限名称" prop="permissionName" min-width="200">
        </el-table-column>
        <el-table-column align="left" label="菜单排序" prop="displayOrder" min-width="120">
        </el-table-column>
        <el-table-column align="left" label="权限编码" prop="permissionCode" min-width="140">
        </el-table-column>
        <el-table-column align="left" label="菜单路由" prop="menuurl" min-width="240">
        </el-table-column>
        <el-table-column align="left" label="扩展信息" prop="extendInfo" min-width="200">
        </el-table-column>
        <!--          <el-table-column-->
        <!--              align="center"-->
        <!--              label="前端端口"-->
        <!--              prop="frontEndPortKey"-->
        <!--              width="100"-->
        <!--          >-->
        <!--          </el-table-column>-->
        <!--          <el-table-column-->
        <!--              align="center"-->
        <!--              label="是否导航"-->
        <!--              prop="isNavigation"-->
        <!--              width="100"-->
        <!--          >-->
        <!--            <template slot-scope="scope">-->
        <!--              <span v-if="scope.row.isNavigation === 0">非导航</span>-->
        <!--              <span v-if="scope.row.isNavigation === 1">导航</span>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--          <el-table-column-->
        <!--              align="center"-->
        <!--              label="是否范围"-->
        <!--              prop="isScope"-->
        <!--              width="200"-->
        <!--          >-->
        <!--            <template slot-scope="scope">-->
        <!--              <span v-if="scope.row.isScope === 0">不启用权限范围</span>-->
        <!--              <span v-if="scope.row.isScope === 1">启用权限范围</span>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--          <el-table-column-->
        <!--              align="center"-->
        <!--              label="范围类型"-->
        <!--              prop="scopeType"-->
        <!--              width="100"-->
        <!--          >-->
        <!--            <template slot-scope="scope">-->
        <!--              <span v-if="scope.row.scopeType === 0">无</span>-->
        <!--              <span v-if="scope.row.scopeType === 1">行政区划</span>-->
        <!--              <span v-if="scope.row.scopeType === 2">组织机构</span>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <el-table-column align="left" label="更新时间" prop="updateTime" min-width="180">
          <template slot-scope="scope">
            <span>{{ dateFormat(scope.row.updateTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="220">
          <template slot-scope="scope">
            <el-button class="common-text" size="small" type="text" @click="goEdit(scope.row)">编辑
            </el-button>
            <el-button class="common-text" size="small" type="text" @click="doAddSon(scope.row)">添加子级
            </el-button>
            <el-button class="common-text" size="small" type="text" @click="goDelete(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加权限 -->
    <el-dialog :before-close="addOrEditDialogBeforeClosed" :title="title" :visible.sync="addOrEditDialogVisible"
      append-to-body class="common-dialog" width="600px" :close-on-click-modal="false">
      <div v-loading="isAddLoading">
        <el-form ref="model" :inline="true" :model="model" :rules="rules" size="medium" label-position="left"
          label-width="100px">
          <el-col :span="24">
            <el-form-item label="权限编码" prop="permissionCode" required>
              <el-input v-model="model.permissionCode" clearable placeholder="请输入权限编码" prefix-icon=""
                style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="权限名称" label-width="100px" prop="permissionName" required>
              <el-input v-model="model.permissionName" clearable placeholder="请输入权限名称" prefix-icon=""
                style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜单路由" label-width="100px" prop="menuurl" required>
              <el-input v-model="model.menuurl" clearable placeholder="请输入菜单路由" prefix-icon=""
                style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜单排序" label-width="100px" prop="displayOrder" required>
              <el-input v-model="model.displayOrder" clearable placeholder="请输入菜单排序" prefix-icon=""
                style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">

            <el-form-item label="扩展信息" label-width="100px" prop="extendInfo" class="norequired">
              <el-input v-model="model.extendInfo" clearable placeholder="请输入扩展信息" prefix-icon=""
                style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">

            <el-form-item label="是否导航" label-width="100px" prop="isNavigation" class="norequired">
              <el-select v-model="model.isNavigation" clearable placeholder="请选择-是否导航" style="width: 100%">
                <el-option :value="0" label="非导航"></el-option>
                <el-option :value="1" label="导航"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="应用平台" label-width="100px" prop="applicationKey" class="norequired">
              <el-select v-model="model.applicationKey" clearable placeholder="请选择-添加场景" style="width: 100%">
                <el-option value="1" label="城市端"></el-option>
                <el-option value="2" label="车场端"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!--          <el-form-item label="权限范围" label-width="100px" prop="isScope">-->
          <!--            <el-select-->
          <!--                v-model="model.isScope"-->
          <!--                clearable-->
          <!--                placeholder="请选择-权限范围"-->
          <!--                style="width: 100%"-->
          <!--            >-->
          <!--              <el-option :value="0" label="不启用权限范围"></el-option>-->
          <!--              <el-option :value="1" label="启用权限范围"></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="范围类型" label-width="100px" prop="scopeType">-->
          <!--            <el-select-->
          <!--                v-model="model.scopeType"-->
          <!--                clearable-->
          <!--                placeholder="请选择-范围类型"-->
          <!--                style="width: 100%"-->
          <!--            >-->
          <!--              <el-option :value="0" label="无"></el-option>-->
          <!--              <el-option :value="1" label="行政区划"></el-option>-->
          <!--              <el-option :value="2" label="组织架构"></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button @click="cancelAddOrEdit">取 消</el-button>
          <el-button type="primary" @click="saveAddOrEdit">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addPermission, getPermission, removePermission, updatePermission, } from "@/api/system";
import BaseMixin from "@/mixins/base";
import { MessageBox } from "element-ui";
import { checkToken } from "@/api/common";

export default {
  name: "power_manage",
  mixins: [BaseMixin],
  data() {
    return {
      isLoading: false,
      total: 0,
      // dataList: [
      //   {
      //     id: 1,
      //     powerNo: "2016-05-02",
      //     powerName: "王小虎",
      //     menuRouter: "上海市普陀区金沙江路 1518 弄",
      //   },
      //   {
      //     id: 2,
      //     powerNo: "2016-05-04",
      //     powerName: "王小虎",
      //     menuRouter: "上海市普陀区金沙江路 1517 弄",
      //   },
      //   {
      //     id: 3,
      //     powerNo: "2016-05-01",
      //     powerName: "王小虎",
      //     menuRouter: "上海市普陀区金沙江路 1519 弄",
      //     children: [
      //       {
      //         id: 31,
      //         powerNo: "2016-05-01",
      //         powerName: "王小虎",
      //         menuRouter: "上海市普陀区金沙江路 1519 弄",
      //       },
      //       {
      //         id: 32,
      //         powerNo: "2016-05-01",
      //         powerName: "王小虎",
      //         menuRouter: "上海市普陀区金沙江路 1519 弄",
      //         children: [
      //           {
      //             id: 88,
      //             powerNo: "2016-05-01",
      //             powerName: "王小虎",
      //             menuRouter: "上海市普陀区金沙江路 1519 弄",
      //           },
      //           {
      //             id: 89,
      //             powerNo: "2016-05-01",
      //             powerName: "王小虎",
      //             menuRouter: "上海市普陀区金沙江路 1519 弄",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     id: 4,
      //     powerNo: "2016-05-03",
      //     powerName: "王小虎",
      //     menuRouter: "上海市普陀区金沙江路 1516 弄",
      //   },
      // ],
      dateList: [],
      addOrEditDialogVisible: false, //添加运营管理人员弹窗
      isAddLoading: false,
      title: "",
      // model:{
      //   permissionCode:"",
      //   permissionName:"",
      //   menuurl:"",
      //   displayOrder:"",
      //   extendInfo:"",
      //   isNavigation:"",
      // },
      model: {
        parentId: "",
        permissionCode: "",
        permissionName: "",
        menuurl: "",
        displayOrder: "0",
        extendInfo: "",
        isNavigation: 1,
        applicationKey:"1",
        isScope: 1,
        scopeType: 0,
        id: "",
      },
      rules: {
        permissionCode: [{ required: true, message: "权限编码不能为空" }],
        permissionName: [{ required: true, message: "权限名称不能为空" }],
        menuurl: [{ required: true, message: "菜单路由不能为空" }],
        displayOrder: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("菜单排序不能为空"));
              } else if (isNaN(value)) {
                callback(new Error("菜单排序必须为数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    doSearch() {
      console.log('doSearch')
      this.search();
    },
    doSort(dataList) {
      dataList.sort((item1, item2) => {
        return item1.displayOrder - item2.displayOrder;
      });

      dataList.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children = this.doSort(item.children);
        }
      });
      return dataList;
    },
    async search() {
      checkToken()
        .then(async res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            this.isLoading = true;
            const res = await getPermission({ pageNo: 1, pageSize: 999 });
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              const returnObject = res.returnObject;
              this.dataList = this.doSort(this.switchListToTree(returnObject, ""));
              this.total = returnObject.length;
            }
          }
        })
    },
    //添加
    doAdd() {
      this.title = "添加权限";
      this.model.id = "";
      this.model.parentId = "";
      this.addOrEditDialogVisible = true;
      this.$nextTick(() => {
        this.model = {
          parentId: "",
          permissionCode: "",
          permissionName: "",
          menuurl: "",
          displayOrder: "0",
          extendInfo: "",
          isNavigation: 1,
          applicationKey:"1",
          isScope: 1,
          scopeType: 0,
          id: "",
        };
      });
    },
    //取消添加或编辑运营人员
    cancelAddOrEdit() {
      this.addOrEditDialogVisible = false;
      this.$refs.model.resetFields();
    },
    // 添加/维护运营人员信息弹窗关闭时清理表单
    addOrEditDialogBeforeClosed(done) {
      this.$refs.model.resetFields();
      done();
    },
    //编辑运营人员
    goEdit(e) {
      this.title = "编辑权限";
      this.model.parentId = e.parentId;
      this.model.permissionCode = e.permissionCode;
      this.model.permissionName = e.permissionName;
      this.model.menuurl = e.menuurl;
      this.model.displayOrder = e.displayOrder;
      this.model.extendInfo = e.extendInfo;
      this.model.isNavigation = e.isNavigation;
      this.model.applicationKey = e.applicationKey;
      this.model.isScope = e.isScope;
      this.model.scopeType = e.scopeType;
      this.model.id = e.id;
      this.addOrEditDialogVisible = true;
    },
    //添加子级
    doAddSon(row) {
      this.title = "添加子级";
      this.model = {
        parentId: "",
        permissionCode: "",
        permissionName: "",
        menuurl: "",
        displayOrder: "0",
        extendInfo: "",
        isNavigation: 1,
        applicationKey:"1",
        isScope: 1,
        scopeType: 0,
        id: "",
      }
      this.model.parentId = row.id;
      this.model.id = "";
      console.log(this.model)
      console.log(this.$refs.model)
      this.addOrEditDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.model.resetFields();
      });
    },
    switchListToTree(dataList, parentId) {
      const menuObject = {};
      dataList.forEach((item) => {
        item.children = [];
        menuObject[item.id] = item;
      });

      return dataList.filter((item) => {
        if (item.parentId !== parentId) {
          menuObject[item.parentId].children.push(item);
          return false;
        }
        return true;
      });
    },
    //确定添加或编辑运营人员
    saveAddOrEdit() {
      console.log('saveAddOrEdit:', this.model)
      this.$refs.model.validate((valid) => {
        if (valid) {
          this.isAddLoading = true;
          if (this.model.id === "") {
            addPermission(this.model).then((res) => {
              setTimeout(() => {
                this.isAddLoading = false;
                if (res && res.code === 30 && res.result) {
                  this.$message.success("保存成功！");
                  this.addOrEditDialogVisible = false;
                  this.model = {
                    parentId: "",
                    permissionCode: "",
                    permissionName: "",
                    menuurl: "",
                    displayOrder: "0",
                    extendInfo: "",
                    isNavigation: 1,
                    applicationKey:"1",
                    isScope: 1,
                    scopeType: 0,
                    id: "",
                  };
                  this.doSearch();
                }
              }, 2000);
            });
          } else {
            updatePermission(this.model).then((res) => {
              setTimeout(() => {
                this.isAddLoading = false;
                if (res && res.code === 30 && res.result) {
                  this.$message.success("编辑成功！");
                  this.addOrEditDialogVisible = false;
                  this.model = {
                    parentId: "",
                    permissionCode: "",
                    permissionName: "",
                    menuurl: "",
                    displayOrder: "0",
                    extendInfo: "",
                    isNavigation: 1,
                    isScope: 1,
                    scopeType: 0,
                    applicationKey: "1",
                    id: "",
                  };
                  this.doSearch();
                }
              }, 2000);
            });
          }
        } else {
          return false;
        }
      });
    },
    //删除
    goDelete(e) {
      if (e.children.length > 0) {
        this.$message.warning("请先删除子权限！");
        return
      }
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const body = {
          id: e.id,
        };
        this.isLoading = true;
        removePermission(body).then((res) => {
          setTimeout(() => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("删除成功！");
              this.doSearch();
            }
          }, 1000);
        });
      });
    },
  },
  // mounted() {
  //   this.search();
  // },
};
</script>

<style lang="less" scoped>
.common-dialog {
  .el-form-item {
    display: flex;
  }

  /deep/ .el-form-item__content {
    width: 100%;
  }

  /deep/ .el-dialog__footer {
    border-top: none !important;
  }
}
</style>
